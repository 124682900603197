import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { useState } from "react";
export default function Stats() {
	const [state, setState] = useState([]);
	const [loader, setLoader] = useState(false);

	return (
		<div className="container gx-0 mt-4 table-responsive">
			<Table
				className="border"
				sx={{ minWidth: 750 }}
				aria-label="simple table"
			>
				<TableHead style={{ backgroundColor: "#222B42" }}>
					<TableRow>
						<TableCell width={"12%"} className="text-white" align="center">
							Matches
						</TableCell>
						<TableCell className="text-white" align="center">
							Win
						</TableCell>
						<TableCell className="text-white" align="center">
							Tennis
						</TableCell>
						<TableCell className="text-white" align="center">
							Leather
						</TableCell>
						<TableCell className="text-white" align="center">
							Ground
						</TableCell>
						<TableCell className="text-white" align="center">
							Turf
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell
							style={{ color: "#222B42" }}
							align="center"
							component="th"
						>
							Overall
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	);
}
