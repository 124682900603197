import { Avatar, Box, Grid, styled, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { MatxLoading, SimpleCard } from 'app/components';
import { H4 } from 'app/components/Typography';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const StyledTable = styled(Table)(({ theme }) => ({
	whiteSpace: "pre",
	"& thead": {
		"& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
	},
	"& tbody": {
		"& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
	},
}));

export default function TeamMember({ teamdetail, loader }) {
	const navigate = useNavigate();

	const APIINITIAL = process.env.REACT_APP_IMG_URL;
	return (
		<Grid container spacing={2} sx={{ mb: 2 }}>
			{(loader && (
				<Grid item lg={12} md={3} sm={12} xs={12}>
					<SimpleCard>
						<div className="text-center">
							<MatxLoading />
						</div>
					</SimpleCard>
				</Grid>
			)) ||
				(teamdetail[0] && teamdetail[0]?.players &&
					teamdetail[0]?.players?.length > 0 &&
					teamdetail[0]?.players.map((item, index) => {
						return (
							<Grid item lg={4} md={3} sm={12} xs={12} key={index} >
								<SimpleCard>
									<Box>
										<Avatar
											src={APIINITIAL + item.image}
											sx={{
												width: 84,
												height: 84,
												margin: "0 auto",
												bgcolor: "#d7dbde",
											}}
										/>
										<H4
											style={{ cursor: "pointer" }}
											className="text-center mt-2"
											onClick={() => {
												navigate(`/player-detail/${item._id}`);
											}}
										>
											{" "}
											{item?.name || "-"}<span> {teamdetail[0]?.captain_name === item?.name
												? "(Captain)"
												: " "}{" "}</span>
										</H4>

										<hr style={{ marginBottom: "0", marginTop: "15px" }} />
									</Box>

									<StyledTable width="100%">
										<TableBody>
											<TableRow>
												<TableCell>Email</TableCell>
												<TableCell>{item?.email || "-"}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Contact Number</TableCell>
												<TableCell>{item?.phone_number || "0"}</TableCell>
											</TableRow>

											<TableRow>
												<TableCell>City</TableCell>
												<TableCell>{item?.city || "-"}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Match played</TableCell>
												<TableCell>{item?.match_played || "-"}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Playing Role</TableCell>
												<TableCell>{item?.roll || "-"}</TableCell>
											</TableRow>
										</TableBody>
									</StyledTable>
								</SimpleCard>
							</Grid>
						);
					})) || <div className="text-center">Data Not Available </div>}
		</Grid>
	)
}
