import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "./contexts/JWTAuthContext";
import { Dialog, DialogContent } from "@mui/material";
import { restriction } from "Assets";

const ListnerProtocols = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const NODE_ENV = process.env.REACT_APP_NODE_ENV

  useEffect(() => {
    // to shift from admin and vendor via keyboard keys
    window.addEventListener("keydown", function (e) {
      if (
        e.altKey &&
        e.key === "v" &&
        location.pathname === "/admin/signin" &&
        !isAuthenticated
      ) {
        navigate("/");
      } else if (e.altKey && e.key === "a" && !isAuthenticated) {
        navigate("/admin/signin");
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    // for disabled mouse scroll on input field type number
    window.addEventListener(
      "mousewheel",
      function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);

  useEffect(() => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => {
        if(NODE_ENV==='PRODUCTION') e.preventDefault();
    });

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    const disableDevToolsShortcuts = (e) => {
      // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, and Ctrl+U
      if(NODE_ENV==='PRODUCTION'){
          if (
            (e.ctrlKey && e.shiftKey && e.keyCode === 73) || 
            (e.ctrlKey && e.shiftKey && e.keyCode === 74) || 
            e.keyCode === 123 ||
            (e.ctrlKey && e.keyCode === 85)
          ) {
            e.preventDefault();
          }
      }
    };

    document.addEventListener("keydown", disableDevToolsShortcuts);

    return () => {
      document.removeEventListener("keydown", disableDevToolsShortcuts);
    };
  }, []);

  const detectDevTools = () => {
    const threshold = 160;
    const widthExceeded = window.outerWidth - window.innerWidth > threshold;
    const heightExceeded = window.outerHeight - window.innerHeight > threshold;
    if(NODE_ENV==='PRODUCTION'){
        if (widthExceeded || heightExceeded) {
        setOpen(true);
        } else {
        setOpen(false);
        }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", detectDevTools);
    return () => {
      window.removeEventListener("resize", detectDevTools);
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (!open) detectDevTools();
    }, 1000);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
        // maxWidth='xs'
      >
        <DialogContent className="p-4 px-5 d-flex justify-content-center flex-column">
          <img src={restriction} alt="img" style={{ width: "200px" }} />
          <p className="text-center fw-bold mt-3 text-dark fs-5">
            DevTools are open!
          </p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ListnerProtocols;
