import AuthGuard from "app/auth/AuthGuard";
import loginRoutes from "./views/Web/login/Login";
// import homeRoutes from './views/Web/home/Home';
import HomeRoutes from "./views/Web/home/Home";
import dashboardRoutes from "./views/Admin/dashboard/DashboardRoutes";
import userRoutes from "./views/Admin/Usermanagment/User";
import aminityRoutes from "./views/Admin/Aminity/Aminity";
import vendorRoutes from "./views/Admin/Vendormanagement/Vendor";
import tournamentRoutes from "./views/Admin/Tournament/Tournament";
import sessionRoutes from "./views/Admin/sessions/SessionRoutes";
import blogRoutes from "./views/Admin/Blog/Blog";
import reportRoutes from "./views/Admin/Reports/Report";
import promoCodeRoutes from "./views/Admin/PromoCode/PromoCode";
import { MatxLayout } from "./components";
import { Navigate } from "react-router-dom";
import NotFound from "./views/Admin/sessions/NotFound";
import rulesRoutes from "./views/Admin/Rules/Rules";
import analyticRoutes from "./views/Admin/Analytics/Analytics";
import venodorRoutes from "./views/Web/vendor/Vendor";
import contactRoutes from "./views/Web/contactUs/ContactUsRoutes";
import blogRouting from "./views/Web/blog/BlogRoutes";
import privacyRoutes from "./views/Web/privacyAndPolicy/PrivacyAndPolicyRoutes";
import termsAndConditionRoutes from "./views/Web/termAndConditation/TermAndConditionRoutes";
import WebLayout from "./components/web/WebLayout/WebLayout";
import vendorDashboardRoutes from "./views/Vendor/vendorDashboard/DashBoardRoutes";
import groundRoutes from "./views/Vendor/ground/GroundRoutes";
import scheduleAndCostRoutes from "./views/Vendor/scheduleAndCost/ScheduleRoutes";
// import vendorPromoCodeRoutes from "./views/Vendor/promoCode/PromoCodeRoutes";
import vendorTournamentRoutes from "./views/Vendor/tournament/TournamentRoutes";
import profileRoutes from "./views/Vendor/profile/ProfileRoutes";
import BookingsRoute from "./views/Vendor/Bookings/BookingsRoute";
import VendorLayout from "./components/vendor/VendorLayout/VendorLayout";
import ScorerLayout from "./components/scorer/ScorerLayout/ScorerLayout";
import contactAndSupportRoutes from "./views/Vendor/contactAndSupport/ContactSupportRoutes";
import webProfileRoutes from "./views/Web/profile/WebProfileRoutes";

import scorerRoute from "./views/Admin/Scorer/scorerRoute";
import managerRoute from "./views/Admin/Manager/managerRoute";

import holidayRoutes from "./views/Vendor/Holidays/HolidaySlotRoutes";
import NotificationRoute from "./views/Vendor/notificarion/NotificationRoute";
import AdminNotificationRoute from "./views/Admin/NotificationManegment/NotificationRoute";
// import OccupancyRoute from "./views/Vendor/Occupancy/OccupancyRoute";
import VerifyVendorRoute from "./views/Vendor/VerifyVendor/VerifyVendorRoute";
import teamRoutes from "./views/Admin/Teams/TeamRoutes";
import { element } from "prop-types";
import ManagerRoutes from "./views/ManagerWeb/ManagerRoute";
import ScorerRoute from "./views/ManagerWeb/ScorerRoute";
import scorerLoginRoutes from "./views/ManagerWeb/scorerLoginRoutes";
import groundsRoutes from "./views/Admin/Turfs/Turfrouts";
import BookingRoutes from "./views/Admin/Bookings/BookingRoute";
import InquiryRoute from "./views/Admin/Inquiry/InquiryRoute";
import settlementRoutes from "./views/Vendor/settlement/settlementRoutes";
import TaxChargesRoute from "./views/Admin/Taxandcharges/TaxChargesRoute";
import SettlementRoute from "./views/Admin/Settlement/SettlementRoute";

import PrivacyAndpolicy from "./views/Web/privacyAndPolicy/PrivacyAndpolicy";
import TermAndCondition from "./views/Web/termAndConditation/TermAndCondition";
import revenueRoutes from "./views/Vendor/revenue/revenueRoutes";
// import NotificationRoute './views/Vendor/Holidays/HolidaySlotRoutes';

const routes = {
  ADMIN: [
    {
      element: (
        <AuthGuard>
          <MatxLayout />
        </AuthGuard>
      ),

      children: [
        ...dashboardRoutes,
        ...userRoutes,
        ...scorerRoute,
        ...managerRoute,
        ...aminityRoutes,
        ...vendorRoutes,
        ...tournamentRoutes,
        ...promoCodeRoutes,
        ...reportRoutes,
        ...blogRoutes,
        ...rulesRoutes,
        ...analyticRoutes,
        ...teamRoutes,
        ...groundsRoutes,
        ...BookingRoutes,
        ...AdminNotificationRoute,
        ...TaxChargesRoute,
        ...SettlementRoute,
        ...InquiryRoute,
      ],
    },
  ],

  VENDOR: [
    {
      element: (
        <AuthGuard>
          <VendorLayout />
        </AuthGuard>
      ),
      children: [
        ...vendorDashboardRoutes,
        ...VerifyVendorRoute,
        ...groundRoutes,
        ...scheduleAndCostRoutes,
        ...vendorTournamentRoutes,
        ...profileRoutes,
        ...BookingsRoute,
        ...contactAndSupportRoutes,
        ...holidayRoutes,
        ...NotificationRoute,
        // ...OccupancyRoute,
        ...settlementRoutes,
        ...revenueRoutes,
        // ...tournamentRoutes,
      ],
    },
    {
      element: <WebLayout />,
      children: [
        ...webProfileRoutes,
        ...HomeRoutes,
        // ...aboutRoutes,
        ...venodorRoutes,
        ...contactRoutes,
        ...blogRouting,
        ...privacyRoutes,
        ...termsAndConditionRoutes,
        // ...turfRoutes,
        { path: "/*", element: <NotFound /> },
      ],
    },
  ],

  MANAGER: [
    {
      element: (
        <AuthGuard>
          <ScorerLayout />
        </AuthGuard>
      ),
      children: [...ManagerRoutes],
    },
  ],

  SCORER: [
    {
      element: (
        <AuthGuard>
          <ScorerLayout />
        </AuthGuard>
      ),
      children: [...ScorerRoute],
    },
  ],

  common: [
    { path: "/*", element: <Navigate to="/" /> },
    ...sessionRoutes,
    ...scorerLoginRoutes,
    {
      element: <WebLayout />,
      children: [
        ...HomeRoutes,
        ...venodorRoutes,
        ...contactRoutes,
        ...blogRouting,
        ...privacyRoutes,
        ...termsAndConditionRoutes,
        ...webProfileRoutes,
        // ...turfRoutes,
      ],
    },
  ],
  nonHeader: [
    { path: "/privacy-policy", element: <PrivacyAndpolicy /> },
    { path: "/term-condition", element: <TermAndCondition /> },
  ],
  nonFooter: [
    {
      element: <WebLayout nonFooter={true} />,
      children: [...loginRoutes],
    },
  ],
};

export default routes;
