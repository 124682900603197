import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MatxLoading } from "app/components";
import { DefaultImg } from "Assets";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlayerDetails, getPlayerStats } from "services/admin/Dashboard";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const PlayerDetail = () => {
  const [dtl, setDtl] = useState([]);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [batsr, setbatSr] = useState();
  const [bataverage, setbatAverage] = useState();
  const [bowlereconomy, setBowlereconomy] = useState();
  const [bowleraverage, setBowleraverage] = useState();
  const id = useParams();

  const getData = async () => {
    // setLoader(true)
    let params = { id: id.id };
    await getPlayerDetails(params)
      .then((res) => {
        // setLoader(false)
        setDtl(res?.data?.data);
      })
      .catch((error) => { });
  };

  const getState = async () => {
    setLoader(true);
    let params = { player_id: id.id };
    await getPlayerStats(params)
      .then((res) => {
        setLoader(false);
        setState(res?.data?.data);
        getsr(res?.data?.data);
      })
      .catch((error) => { });
  };

  const getsr = (stateData) => {
    let bat_run =
      Number(stateData?.tennis?.batting?.runs_scored || 0) +
      Number(stateData?.leather?.batting?.runs_scored || 0);
    let bat_ball =
      Number(stateData?.tennis?.batting?.balls_faced || 0) +
      Number(stateData?.leather?.batting?.balls_faced || 0);
    let bat_match =
      Number(stateData?.tennis?.batting?.match || 0) +
      Number(stateData?.leather?.batting?.match || 0);
    let bowl_run =
      Number(stateData?.tennis?.bowling?.runs_conceded || 0) +
      Number(stateData?.leather?.bowling?.runs_conceded || 0);
    let bowl_over =
      Number(stateData?.tennis?.bowling?.overs_bowled || 0) +
      Number(stateData?.leather?.bowling?.overs_bowled || 0);
    let bowl_ball =
      Number(stateData?.tennis?.bowling?.ball || 0) +
      Number(stateData?.leather?.bowling?.ball || 0);
    let bowl_wkt =
      Number(stateData?.tennis?.bowling?.wickets || 0) +
      Number(stateData?.leather?.bowling?.wickets || 0);
    const str =
      Number(bat_ball) > 0 ? (Number(bat_run) / Number(bat_ball)) * 100 : 0;
    let Avg = Number(bat_match) > 0 ? Number(bat_run) / Number(bat_match) : 0;
    let Bowl_eco =
      Number(bowl_over) > 0 ? Number(bowl_run) / Number(bowl_over) : 0;
    let Bowl_ave =
      Number(bowl_wkt) > 0 ? Number(bowl_run) / Number(bowl_wkt) : 0;

    setBowlereconomy(Bowl_eco);
    setbatAverage(Avg);
    setbatSr(str);
    setBowleraverage(Bowl_ave);
  };

  useEffect(() => {
    getData();
    getState();
  }, []);

  let APIINITIAL = process.env.REACT_APP_IMG_URL;

  let roleobj = {
    BATTER: "Batter",
    BOWLER: "Bowler",
    ALLROUNDER: "All rounder",
    WICKETKEEPER: "Wicket keeper",
  };

  let genderobj = {
    MALE: "Male",
    FEMALE: "Female",
  };

  let batstyleobj = {
    LEFTHAND: "Left hand",
    RIGHTHAND: "Right hand",
  };

  let ballstyleobj = {
    LEFTHAND: "Left hand",
    RIGHTHAND: "Right hand",
  };


  return (
    <Container>
      <div style={{ backgroundColor: "#222B42" }}>
        <div>
          <div className="row">
            <div className="col-sm-6 pt-5 pb-4">
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">

                <img
                  style={{
                    width: 300,
                    height: 300,
                    // boxShadow: "inset 0 -20px 12px -6px #222B42",
                    // border: "5px solid white",
                    objectFit: "cover",
                  }}
                  src={dtl?.image && APIINITIAL + dtl?.image || DefaultImg}
                  onError={() => DefaultImg}
                  alt="server problem"
                />

                <div className="player-name mt-3 text-white">
                  <h6 className="text-white mb-0">{dtl?.name || "-"}</h6>
                </div>
              </div>
            </div>

            <div className="col-sm-6 pt-md-5 pt-sm-0 pb-4 d-flex justify-content-center pe-md-5">
              <div className="bg-grey w-75 pt-sm-0  pb-5 pt-4 ">
                {/* <h2 className="h2-chart text-white pb-2">Player Overview</h2> */}
                <TableContainer
                  component={Paper}
                  style={{
                    backgroundColor: "#fff0",
                    border: "1px solid white",
                  }}
                >
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.email || "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Phone number
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.phone_number || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Joining date
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.created_at?.split("T")[0] || "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Playing Role
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {roleobj[dtl?.roll]}
                          {/* {dtl?.roll || "-"} */}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Jersy number
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.jersey_number || "-"}
                        </TableCell>
                      </TableRow>

                      {(dtl?.batting_style && (
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                              fontWeight: "300",
                            }}
                          >
                            Batting Style
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                            }}
                            align="center"
                          >
                            {batstyleobj[dtl?.batting_style]}
                            {/* {dtl?.batting_style || "-"} */}
                          </TableCell>
                        </TableRow>
                      )) ||
                        " "}

                      {(dtl?.bowling_style && (
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                              fontWeight: "300",
                            }}
                          >
                            Bowling Style
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                            }}
                            align="left"
                          >
                            {ballstyleobj[dtl?.bowling_style]}
                            {/* {dtl?.bowling_style || "-"} */}
                          </TableCell>
                        </TableRow>
                      )) ||
                        " "}

                      {/* <TableRow>
                    <TableCell style={{ borderRight: '1px solid white', color: 'white', textAlign: 'center',  fontWeight:'300' }}>Gender</TableCell>
                    <TableCell style={{ borderRight: '1px solid white', color: 'white', textAlign: 'center' }} align="left">
                      {genderobj[dtl?.gender]}
                    </TableCell>
                  </TableRow> */}
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          City
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="left"
                        >
                          {dtl?.city || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(loader && (
        <div className="text-center mt-2">
          <MatxLoading />
        </div>
      )) || (
          <>
            {state?.leather?.batting || state?.tennis?.batting ? (
              <div className=" mt-4 table-responsive">
                <h2 className="mb-4 h2-chart">Batting Stats</h2>
                <Table
                  className="border"
                  sx={{ minWidth: 750 }}
                  aria-label="simple table"
                >
                  <TableHead style={{ backgroundColor: "#222B42" }}>
                    <TableRow>
                      <TableCell
                        width={"12%"}
                        className="text-white"
                        align="center"
                      >
                        Batting
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Matches
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Innings
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Not Out
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Runs
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Highest
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Average
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        SR
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        30s
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        50s
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        100s
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        4s
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        6s
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Duck
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Won
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Loss
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Overall
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.match ||
                          0 + state?.leather?.batting?.match ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.innings ||
                          0 + state?.leather?.batting?.innings ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.not_out ||
                          0 + state?.leather?.batting?.not_out ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.runs_scored ||
                          0 + state?.leather?.batting?.runs_scored ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.highest >
                          state?.leather?.batting?.highest
                          ? state?.tennis?.batting?.highest
                          : state?.leather?.batting?.highest}
                      </TableCell>
                      <TableCell align="center">
                        {Number(bataverage) > 0
                          ? Number(bataverage).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(batsr) > 0 ? Number(batsr).toFixed(2) : 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.thirty ||
                          0 + state?.leather?.batting?.thirty ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.fifty ||
                          0 + state?.leather?.batting?.fifty ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.hundred ||
                          0 + state?.leather?.batting?.hundred ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.fours ||
                          0 + state?.leather?.batting?.fours ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.sixes ||
                          0 + state?.leather?.batting?.sixes ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.duck ||
                          0 + state?.leather?.batting?.duck ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.won ||
                          0 + state?.leather?.batting?.won ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.loss ||
                          0 + state?.leather?.batting?.loss ||
                          0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Leather Ball
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.match || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.innings || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.not_out || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.runs_scored || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.highest || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.leather?.batting?.avg) > 0
                          ? Number(state?.leather?.batting?.avg).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.leather?.batting?.sr) > 0
                          ? Number(state?.leather?.batting?.sr).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.thirty || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.fifty || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.hundred || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.fours || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.sixes || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.duck || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.won || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.batting?.loss || "0"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Tennis ball
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.match || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.innings || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.not_out || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.runs_scored || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.highest || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.tennis?.batting?.avg) > 0
                          ? Number(state?.tennis?.batting?.avg).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.tennis?.batting?.sr) > 0
                          ? Number(state?.tennis?.batting?.sr).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.thirty || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.fifty || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.hundred || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.fours || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.sixes || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.duck || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.won || "0"}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.batting?.loss || "0"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ) : (
              ""
            )}

            {state?.leather?.bowling || state?.tennis?.bowling ? (
              <div className="mt-4  table-responsive">
                <h2 className="mb-4 h2-chart">Bowling Stats</h2>
                <Table
                  className="border"
                  sx={{ minWidth: 750 }}
                  aria-label="simple table"
                >
                  <TableHead style={{ backgroundColor: "#222B42" }}>
                    <TableRow>
                      <TableCell
                        width={"12%"}
                        className="text-white"
                        align="center"
                      >
                        Bowling
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Matches
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Innings
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Over
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Maidens
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Wickets
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Run
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Best/B
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        4 Wk
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        5 Wk
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Eco
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Avg
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Wides
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        No Ball
                      </TableCell>
                      <TableCell className="text-white" align="center">
                        Dots Ball
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Overall
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match ||
                          0 + state?.leather?.bowling?.match ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.innings ||
                          0 + state?.leather?.bowling?.innings ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.overs_bowled ||
                          0 + state?.leather?.bowling?.overs_bowled ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.maidens ||
                          0 + state?.leather?.bowling?.maidens ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.wickets ||
                          0 + state?.leather?.bowling?.wickets ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.runs_conceded ||
                          0 + state?.leather?.bowling?.runs_conceded ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match ||
                          0 + state?.leather?.bowling?.match ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.fourWk ||
                          0 + state?.leather?.bowling?.fourWk ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.fiveWk ||
                          0 + state?.leather?.bowling?.fiveWk ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(bowlereconomy) > 0
                          ? Number(bowlereconomy).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(bowleraverage) > 0
                          ? Number(bowleraverage).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.wides ||
                          0 + state?.leather?.bowling?.wides ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.no_ball ||
                          0 + state?.leather?.bowling?.no_ball ||
                          0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match ||
                          0 + state?.leather?.bowling?.match ||
                          0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Leather Ball
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.match || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.innings || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.overs_bowled || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.maidens || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.wickets || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.runs_conceded || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.match || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.fourWk || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.fiveWk || 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.leather?.bowling?.eco) > 0
                          ? Number(state?.leather?.bowling?.eco).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.leather?.bowling?.ballAvg) > 0
                          ? Number(state?.leather?.bowling?.ballAvg).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.wides || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.no_ball || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.leather?.bowling?.match || 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ color: "#222B42" }}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        Tennis ball
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.innings || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.overs_bowled || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.maidens || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.wickets || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.runs_conceded || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.fourWk || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.fiveWk || 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.tennis?.bowling?.eco) > 0
                          ? Number(state?.tennis?.bowling?.eco).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {Number(state?.tennis?.bowling?.ballAvg) > 0
                          ? Number(state?.tennis?.bowling?.ballAvg).toFixed(2)
                          : 0}
                        {/* {state?.tennis?.bowling?.ballAvg || 0} */}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.wides || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.no_ball || 0}
                      </TableCell>
                      <TableCell align="center">
                        {state?.tennis?.bowling?.match || 0}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ) : (
              ""
            )}
          </>
        )}
    </Container>
  );
};

export default PlayerDetail;
